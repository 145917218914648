





















import { defineComponent, onMounted, reactive, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  components: {
    OTermsOfService: () => import('@/components/organisms/event/website/config/consents/o-terms-of-service.vue'),
    OPrivacyPolicy: () => import('@/components/organisms/event/website/config/consents/o-privacy-policy.vue'),
    OPersonalDataProtection: () => import('@/components/organisms/event/website/config/consents/o-personal-data-protection.vue')
  },

  setup(_, { root }) {

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      loaded: false,
      privacyPolicy: [],
      termsOfService: [],
      personalDataProtection: []
    })

    const model = reactive({
      privacyPolicy: '',
      termsOfService: '',
      personalDataProtection: '',
      privacyPolicyContent: '',
      termsOfServiceContent: '',
      personalDataProtectionContent: ''
    })

    const fetchConsents = () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

      state.loading = true

      axiosInstance
        .get('consent')
        .then(({ data: { consents } }) => {

          state.privacyPolicy = consents.filter((obj: any) => (obj.type == 'privacy policy'))
          state.termsOfService = consents.filter((obj: any) => (obj.type == 'terms of service'))
          state.personalDataProtection = consents.filter((obj: any) => (obj.type == 'personal data protection'))

        })
        .catch((error) => console.log(error))
        .finally(() => state.loading = false)
    }

    onMounted(fetchConsents)

    const fetchSingle = () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

      state.loading = true

      axiosInstance
        .get(`consent/${model.privacyPolicy}`)
        .then(({ data: { consent }}) => {
          model.privacyPolicyContent = consent.template
        })
        .catch((error) => console.log(error))
        .finally(() => state.loading = false)
    }

    watch(() => model.privacyPolicy, fetchSingle)

    return { state, model }
  }
})
